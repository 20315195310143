var Application = function(params){
	var self = this;

	self.csrf = params && params.csrf || {};
	self.user = new User(params && params.user || {});
	self.urls = params && params.urls || {};

	// normalize URLs
	self.urls.ajax = self.urls.ajax || self.urls.base + 'ajax';

	self.ajaxRequest = function(action, params, callback) {
		if(typeof(params) === 'function') {
			callback = params;
			params = {};
		}
		var opts = {
			type: 'POST',
			data: $.extend(params||{}, self.csrf),
			dataType: 'json',
		};
		if(action) {
			if(action.substring(0, 1) === '/')
				opts.url = action;
			else
				opts.url = self.urls.ajax + '/' + action;
		}
		return $.ajax(opts).done(function(r){
			callback && callback(r);
		});
	};

	self.injectHtml = function(target, html) {
		var container = document.createElement('div');
		container.innerHTML = html;
		ko.applyBindings(self, container);
		target.innerHTML = container;
	};

	self.auth = function() {
		if(!self.user.isGuest())
			return true;
		var url = self.urls.login;
		if(url.indexOf('?') === -1)
			url += '?';
		else
			url += '&';
		// redirect
		window.location.href = url + 'u=' + encodeURIComponent(window.location.href);

		// popup here ...

		return false;
	};
};