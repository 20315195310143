!(function($) {
	'use strict';

	// FIXES

	// fix $current.closest(...).size is not a function
	$.fn.size = function() { return this.length; }

	// fix for Android stock browser
	var ua = navigator.userAgent;
	if(ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1 && ua.indexOf('Chrome') === -1)
	{
		$('select.form-control').removeClass('form-control').css('width', '100%');
	}

	// apply all common plugins

	$.fn.initPlugins = function() {
		$('.tip', this).each(function() {
			bootstrap.Tooltip.getOrCreateInstance(this, {
				'placement': 'top',
			})
		})

		// proxy input
		$('.proxyinput', this).each(function(){
			$(this).selectize({
				create: true,
				createFilter: /^[0-9A-Za-zА-Яа-яЁё!\?:\-\+;" ]{2,30}$/,
				createOnBlur: true,
				closeAfterSelect: true,
				openOnFocus: false,
				delimiter: ",",
				maxOptions: 7,
				maxItems: 50,
				dropdownParent: 'body',
				plugins: ['remove_button'],
				load: function(e, callback) {
					App.ajaxRequest('proxy/search', {query: e}).done(function(r) {
						callback($.map(r.items, function(item) {
							return { value: item['name'], text: item['name'] };
						}));
					});
				}
			});
		});

		// tags input
		$('.tagsinput', this).each(function(){
			$(this).selectize({
				create: true,
				createFilter: /^[0-9A-Za-zА-Яа-яЁё!\?:\-\+;" ]{2,30}$/,
				createOnBlur: true,
				closeAfterSelect: true,
				openOnFocus: false,
				delimiter: ",",
				maxOptions: 7,
				maxItems: 50,
				dropdownParent: 'body',
				plugins: ['remove_button'],
				load: function(e, callback) {
					App.ajaxRequest('tags/search', {query: e}).done(function(r) {
						callback($.map(r.items, function(item) {
							return { value: item['name'], text: item['name'] };
						}));
					});
				}
			});
		});

	};

	$(document).ready(function() {
		window.App = new Application(window.__app || {});
		ko.options.deferUpdates = true;
		ko.applyBindings(window.App);

		$(document).initPlugins();
	});

})(jQuery);